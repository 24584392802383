import React, { useState } from "react";
import {
    Container,
    TextField,
    Button,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip
} from "@mui/material";

function PancakeV2Tracker() {
    const [swapAddress] = useState("0x10ED43C718714eb63d5aA57B78B54704E256024E");
    const [tokenAddress, setTokenAddress] = useState("");
    const [poolToken, setPoolToken] = useState("WBNB");
    const [transactionRecords, setTransactionRecords] = useState([]);
    const [ws, setWs] = useState(null);
    const WBNB = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
    const USDT = "0x55d398326f99059ff775485246999027b3197955";

    const startMonitoring = async () => {
        //const socket = new WebSocket("ws://localhost:8899/ws");
        const socket = new WebSocket("wss://wuguiym.com/ws");

        socket.onopen = () => {
            console.log("连接成功");
            const message = JSON.stringify({
                base_address: tokenAddress,
                quota_address: poolToken === "WBNB" ? WBNB : USDT,
            });
            socket.send(message);
            console.log("发送的参数:", message);
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log("message:", message);
            setTransactionRecords((prevRecords) => [
                ...prevRecords,
                {
                    wallet_address: message.wallet_address,
                    tx_hash: message.tx_hash,
                    trade_type: message.trade_type,
                    amount_in: message.amount_in,
                    amount_out: message.amount_out,
                    price: message.price,
                    block_number: message.block_number,
                },
            ]);
        };

        socket.onclose = () => {
            console.log("WebSocket 连接已关闭");
        };

        socket.onerror = (error) => {
            console.error("WebSocket 发生错误:", error);
        };

        setWs(socket);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert("地址已复制到剪贴板！");
        });
    };

    const formatAddress = (address) => {
        if (!address || address.length <= 12) {
            return address;
        }
        return `${address.slice(0, 6)}...${address.slice(-6)}`;
    };

    const formatScientificPrice = (price) => {
        if (!price || price === 0) return "0";

        const strPrice = price.toString();
        const [integerPart, decimalPart] = strPrice.split(".");

        if (!decimalPart) return `${integerPart}`;

        const leadingZeros = decimalPart.match(/^0+/);
        const zeroCount = leadingZeros ? leadingZeros[0].length : 0;
        const significantDigits = decimalPart.slice(zeroCount, zeroCount + 3);

        if (significantDigits.length <= 2) {
            return `0.${"0".repeat(zeroCount)}${significantDigits}`;
        }

        return `0.0{${zeroCount}}${significantDigits}`;
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: "20px" }}>
            <Grid container spacing={2} alignItems="center" style={{ marginBottom: "20px" }}>
                <Grid item xs={4}>
                    <TextField
                        label="购买的Swap地址"
                        value={swapAddress}
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="代币合约地址"
                        value={tokenAddress}
                        onChange={(e) => setTokenAddress(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        select
                        label="选择底池类型"
                        value={poolToken}
                        onChange={(e) => setPoolToken(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value="WBNB">WBNB</MenuItem>
                        <MenuItem value="USDT">USDT</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={startMonitoring}
                    >
                        开始监控
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper} elevation={3}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>钱包地址</TableCell>
                            <TableCell>交易哈希</TableCell>
                            <TableCell>买卖类型</TableCell>
                            <TableCell>输入数量</TableCell>
                            <TableCell>获得数量</TableCell>
                            <TableCell>持币均价</TableCell>
                            <TableCell>区块高度</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactionRecords.map((record, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    onClick={() => copyToClipboard(record.wallet_address)}
                                    style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                                >
                                    <Tooltip title={record.wallet_address} style={{
                                        color: record.trade_type === "buy" ? "green" : "red",
                                    }}>
                                        <span>{formatAddress(record.wallet_address)}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    onClick={() => window.open(`https://bscscan.com/tx/${record.tx_hash}`, "_blank")}
                                    style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                                >
                                    <Tooltip title={record.tx_hash} style={{
                                        color: record.trade_type === "buy" ? "green" : "red",
                                    }}>
                                        <span>{formatAddress(record.tx_hash)}</span>
                                    </Tooltip>
                                </TableCell>

                                <TableCell
                                    style={{
                                        color: record.trade_type === "buy" ? "green" : "red",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {record.trade_type === "buy" ? "买入" : "卖出"}
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: record.trade_type === "buy" ? "green" : "red",
                                    }}
                                >{record.amount_in}</TableCell>
                                <TableCell
                                    style={{
                                        color: record.trade_type === "buy" ? "green" : "red",
                                    }}
                                >{record.amount_out}</TableCell>
                                <TableCell
                                    style={{
                                        color: record.trade_type === "buy" ? "green" : "red",
                                    }}
                                >{formatScientificPrice(record.price)}</TableCell>
                                <TableCell
                                    style={{
                                        color: record.trade_type === "buy" ? "green" : "red",
                                    }}
                                >{record.block_number}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default PancakeV2Tracker;
